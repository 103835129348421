<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="218" height="114" viewBox="0 0 218 114" fill="none">
    <path d="M125.705 12.7317L129.882 7.77387C115.76 -4.02579 94.6301 -2.18402 82.7773 11.879C77.0365 18.6916 74.3045 27.3233 75.0828 36.1819C75.1446 36.892 75.2293 37.5989 75.3368 38.2993C76.5513 46.3149 80.6086 53.5263 86.8997 58.7858L82.722 63.7404C89.013 68.9966 96.8509 71.7203 104.988 71.5225C105.698 71.5063 106.411 71.4642 107.128 71.4025C116.024 70.6276 124.086 66.4479 129.827 59.6353C141.673 45.5724 139.827 24.5314 125.705 12.7317ZM119.873 51.3214C116.362 55.4881 111.432 58.04 105.994 58.5166C100.55 58.9933 95.2553 57.3266 91.0743 53.8311L95.252 48.8765C91.0677 45.381 88.5018 40.4783 88.0264 35.0567C87.551 29.6417 89.2214 24.3628 92.7317 20.1961C99.9769 11.5968 112.898 10.4717 121.53 17.6864L117.352 22.641C125.988 29.8589 127.118 42.7221 119.873 51.3214Z" fill="#FF4438"/>
    <path d="M214.9 86.1658C213.203 86.1658 211.826 87.5568 211.826 89.2689C211.826 90.9842 213.207 92.3785 214.9 92.3785C216.61 92.3785 218 90.9842 218 89.2689C218 87.5568 216.61 86.1658 214.9 86.1658ZM217.16 89.2689C217.16 90.5789 216.167 91.6068 214.9 91.6068C213.633 91.6068 212.643 90.5789 212.643 89.2689C212.643 87.9622 213.637 86.9375 214.9 86.9375C216.167 86.9375 217.16 87.9622 217.16 89.2689Z" fill="white"/>
    <path d="M216.134 88.6659C216.134 88.0855 215.662 87.6477 215.034 87.6477H213.91V90.8449H214.587V89.684H214.75L215.402 90.8449H216.18L215.47 89.6094C215.877 89.457 216.134 89.0906 216.134 88.6659ZM214.587 88.2443H214.946C215.268 88.2443 215.444 88.3967 215.444 88.6723C215.444 88.9382 215.268 89.0842 214.946 89.0842H214.587V88.2443Z" fill="white"/>
    <path d="M9.886 84.3987C12.7059 84.3987 15.4249 85.1023 18.0364 86.5063V91.4642C15.0341 89.6613 12.3738 88.7599 10.0521 88.7599C8.64211 88.7599 7.53823 89.0323 6.7437 89.5771C5.94917 90.1218 5.55192 90.8481 5.55192 91.756C5.55192 92.8455 6.29109 93.8507 7.77269 94.7716C8.18623 95.0472 9.78505 95.819 12.5659 97.0836C14.943 98.199 16.659 99.4377 17.7108 100.803C18.7626 102.165 19.2868 103.805 19.2868 105.718C19.2868 108.196 18.3913 110.196 16.6004 111.717C14.8095 113.238 12.491 114 9.64178 114C7.78897 114 6.19666 113.796 4.86159 113.39C3.52653 112.985 2.02863 112.285 0.371198 111.292V105.8C3.4679 108.361 6.38551 109.642 9.12402 109.642C10.4917 109.642 11.5988 109.347 12.4422 108.754C13.2855 108.16 13.7088 107.375 13.7088 106.399C13.7088 105.064 12.9762 103.977 11.5109 103.134C10.293 102.473 8.7235 101.682 6.80231 100.757C4.51967 99.7262 2.81667 98.52 1.69 97.1419C0.563334 95.7638 0 94.1685 0 92.3494C0 89.9272 0.905229 87.9947 2.71571 86.555C4.52293 85.1185 6.91303 84.3987 9.886 84.3987Z" fill="white"/>
    <path d="M32.7157 93.9609C34.7346 93.9609 36.5679 94.3825 38.2123 95.2223C39.8567 96.0621 41.1299 97.2392 42.0286 98.7534C42.9273 100.268 43.3767 101.983 43.3767 103.896C43.3767 105.894 42.9436 107.641 42.0807 109.143C41.2145 110.644 39.9446 111.827 38.2644 112.696C36.5841 113.565 34.7346 113.997 32.7157 113.997C30.6838 113.997 28.8375 113.562 27.1768 112.696C25.5161 111.827 24.2559 110.644 23.3898 109.143C22.5236 107.641 22.0938 105.894 22.0938 103.896C22.0938 101.954 22.5497 100.229 23.4614 98.721C24.3732 97.2132 25.6464 96.0427 27.2777 95.2093C28.9124 94.3792 30.7229 93.9609 32.7157 93.9609ZM27.4471 103.896C27.4471 105.825 27.9192 107.362 28.8668 108.514C29.8144 109.665 31.0843 110.239 32.6734 110.239C34.3341 110.239 35.6398 109.687 36.5939 108.585C37.548 107.482 38.0267 105.907 38.0267 103.854C38.0267 101.899 37.5447 100.388 36.5841 99.3209C35.6235 98.2541 34.321 97.7191 32.6734 97.7191C31.1234 97.7191 29.8665 98.2833 28.8994 99.4117C27.9323 100.543 27.4471 102.038 27.4471 103.896Z" fill="white"/>
    <path d="M52.3085 84.7683V113.624H47.206V84.7683H52.3085Z" fill="white"/>
    <path d="M66.7566 93.9609C68.7755 93.9609 70.6087 94.3825 72.2531 95.2223C73.8975 96.0621 75.1707 97.2392 76.0695 98.7534C76.9682 100.268 77.4176 101.983 77.4176 103.896C77.4176 105.894 76.9845 107.641 76.1216 109.143C75.2554 110.644 73.9855 111.827 72.3052 112.696C70.625 113.565 68.7755 113.997 66.7566 113.997C64.7247 113.997 62.8784 113.562 61.2177 112.696C59.557 111.827 58.2968 110.644 57.4306 109.143C56.5645 107.641 56.1346 105.894 56.1346 103.896C56.1346 101.954 56.5905 100.229 57.5023 98.721C58.414 97.2132 59.6872 96.0427 61.3186 95.2093C62.9533 94.3792 64.7637 93.9609 66.7566 93.9609ZM61.4879 103.896C61.4879 105.825 61.9601 107.362 62.9077 108.514C63.8552 109.665 65.1252 110.239 66.7142 110.239C68.3749 110.239 69.6807 109.687 70.6348 108.585C71.5889 107.482 72.0675 105.907 72.0675 103.854C72.0675 101.899 71.5856 100.388 70.625 99.3209C69.6644 98.2541 68.3619 97.7191 66.7142 97.7191C65.1643 97.7191 63.9073 98.2833 62.9402 99.4117C61.9699 100.543 61.4879 102.038 61.4879 103.896Z" fill="white"/>
    <path d="M89.2313 84.3987C90.4622 84.3987 92.0838 84.7521 92.5918 84.9078V89.1361C91.374 88.8021 88.4824 88.4908 87.4111 89.9208C86.8868 90.6212 86.6394 91.4772 86.6394 92.9525V94.3371H92.1457V98.1795H86.6394V113.63H81.5368V98.1795H78.7787V94.3371H81.5368V92.8909C81.5368 90.2872 82.2369 88.2217 83.6436 86.6944C85.0438 85.1607 86.9096 84.3987 89.2313 84.3987Z" fill="white"/>
    <path d="M104.588 93.9609C106.607 93.9609 108.44 94.3825 110.084 95.2223C111.729 96.0621 113.002 97.2392 113.901 98.7534C114.799 100.268 115.249 101.983 115.249 103.896C115.249 105.894 114.816 107.641 113.953 109.143C113.087 110.644 111.817 111.827 110.136 112.696C108.456 113.565 106.607 113.997 104.588 113.997C102.556 113.997 100.71 113.562 99.0489 112.696C97.3882 111.827 96.1281 110.644 95.2619 109.143C94.3957 107.641 93.9659 105.894 93.9659 103.896C93.9659 101.954 94.4218 100.229 95.3335 98.721C96.2453 97.2132 97.5185 96.0427 99.1499 95.2093C100.785 94.3792 102.595 93.9609 104.588 93.9609ZM99.3192 103.896C99.3192 105.825 99.7914 107.362 100.739 108.514C101.686 109.665 102.956 110.239 104.545 110.239C106.206 110.239 107.512 109.687 108.466 108.585C109.42 107.482 109.899 105.907 109.899 103.854C109.899 101.899 109.417 100.388 108.456 99.3209C107.496 98.2541 106.193 97.7191 104.545 97.7191C102.996 97.7191 101.739 98.2833 100.771 99.4117C99.8044 100.543 99.3192 102.038 99.3192 103.896Z" fill="white"/>
    <path d="M128.515 93.9609C130.534 93.9609 132.367 94.3825 134.011 95.2223C135.656 96.0621 136.929 97.2392 137.828 98.7534C138.726 100.268 139.176 101.983 139.176 103.896C139.176 105.894 138.743 107.641 137.88 109.143C137.014 110.644 135.744 111.827 134.063 112.696C132.383 113.565 130.534 113.997 128.515 113.997C126.483 113.997 124.636 113.562 122.976 112.696C121.315 111.827 120.055 110.644 119.189 109.143C118.323 107.641 117.893 105.894 117.893 103.896C117.893 101.954 118.349 100.229 119.26 98.721C120.172 97.2132 121.445 96.0427 123.077 95.2093C124.711 94.3792 126.525 93.9609 128.515 93.9609ZM123.246 103.896C123.246 105.825 123.718 107.362 124.666 108.514C125.613 109.665 126.883 110.239 128.472 110.239C130.133 110.239 131.439 109.687 132.393 108.585C133.347 107.482 133.826 105.907 133.826 103.854C133.826 101.899 133.344 100.388 132.383 99.3209C131.423 98.2541 130.12 97.7191 128.472 97.7191C126.922 97.7191 125.665 98.2833 124.698 99.4117C123.731 100.543 123.246 102.038 123.246 103.896Z" fill="white"/>
    <path d="M155.926 94.7651V84.7683H161.009V113.624H152.048C148.951 113.624 146.47 112.735 144.61 110.958C142.751 109.182 141.82 106.759 141.82 103.689C141.82 101.87 142.253 100.219 143.116 98.7308C143.979 97.2424 145.17 96.0751 146.685 95.2288C148.199 94.3825 149.833 93.9577 151.592 93.9577C152.946 93.9609 154.392 94.2301 155.926 94.7651ZM153.253 109.742H155.929V98.5686C154.877 98.0304 153.787 97.7645 152.65 97.7645C150.95 97.7645 149.589 98.2962 148.573 99.3566C147.557 100.417 147.049 101.808 147.049 103.53C147.049 107.67 149.117 109.742 153.253 109.742Z" fill="white"/>
    <path d="M168.404 85.4104C169.192 85.4104 169.869 85.6925 170.436 86.2567C171.002 86.8209 171.286 87.4954 171.286 88.2801C171.286 89.0388 171.006 89.71 170.446 90.2937C169.886 90.8806 169.205 91.1724 168.404 91.1724C167.629 91.1724 166.955 90.8806 166.382 90.2937C165.809 89.71 165.522 89.0388 165.522 88.2801C165.522 87.4824 165.812 86.8047 166.395 86.247C166.974 85.6893 167.645 85.4104 168.404 85.4104ZM170.957 94.3339V113.627H165.854V94.3339H170.957Z" fill="white"/>
    <path d="M184.47 93.9609C187.32 93.9609 189.573 94.8689 191.234 96.6879C192.894 98.507 193.721 101.02 193.721 104.227V104.992H180.009C180.218 106.714 180.862 108.04 181.95 108.967C183.034 109.898 184.526 110.362 186.421 110.362C187.639 110.362 188.811 110.151 189.938 109.733C191.064 109.311 192.23 108.676 193.432 107.823V111.604C191.165 113.202 188.41 114 185.177 114C182.119 114 179.625 113.086 177.688 111.253C175.75 109.421 174.783 106.986 174.783 103.942C174.783 101.007 175.678 98.6108 177.469 96.7528C179.26 94.8916 181.595 93.9609 184.47 93.9609ZM188.931 101.915C188.791 100.511 188.365 99.4117 187.645 98.6205C186.926 97.8293 185.936 97.4337 184.679 97.4337C183.393 97.4337 182.36 97.8131 181.579 98.5686C180.797 99.3274 180.302 100.443 180.097 101.915H188.931V101.915Z" fill="white"/>
    <path d="M203.588 93.9609C204.653 93.9609 205.655 94.0809 206.597 94.3209C207.538 94.5608 208.534 94.9434 209.583 95.4687V99.5381C208.615 98.9188 207.577 98.4195 206.47 98.0401C205.362 97.6607 204.376 97.4726 203.503 97.4726C202.77 97.4726 202.197 97.6088 201.78 97.8844C201.367 98.1601 201.159 98.5524 201.159 99.0615C201.159 99.3501 201.331 99.6387 201.676 99.9305C202.021 100.222 202.969 100.751 204.519 101.523C206.73 102.638 208.283 103.666 209.175 104.61C210.068 105.553 210.514 106.685 210.514 108.008C210.514 109.866 209.863 111.331 208.563 112.398C207.264 113.465 205.506 114 203.295 114C201.911 114 200.621 113.848 199.426 113.546C198.231 113.244 197.238 112.878 196.45 112.45V108.215C198.938 109.593 201.035 110.281 202.735 110.281C203.607 110.281 204.311 110.125 204.851 109.817C205.392 109.506 205.659 109.097 205.659 108.588C205.659 108.287 205.561 108.011 205.369 107.761C205.174 107.515 204.884 107.259 204.496 106.996C204.109 106.733 202.897 106.108 200.866 105.115C199.302 104.373 198.147 103.562 197.401 102.69C196.655 101.814 196.281 100.78 196.281 99.5803C196.281 97.8715 196.951 96.5096 198.293 95.4914C199.638 94.47 201.4 93.9609 203.588 93.9609Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'logo-white-vertical'
}
</script>

<style scoped>

</style>
