<template>
  <div class="block p-normal pt-10 guest-container">
    <Logo></Logo>
    <div>
      <h1 class="font-bold">¡Descubre, Conecta y Colabora!</h1>
      <h1 class="text-center font-light">Alcanza nuevas audiencias</h1>
    </div>
    <div class="flex flex-col items-center justify-center dark:text-gray-500 text-center mt-normal">
      <p class="">¿No tienes cuenta?</p>
      <p @click="handleUser" class="text-primary font-bold w-auto cursor-pointer select-none">
          Regístrate
      </p>
    </div>
    <form @submit.prevent="handleLogin" @keydown="clear($event.target.id)" class="w-full login-form">
      <div class="p-normal">
        <div class="block-input">
          <input
            type="email"
            id="email"
            name="email"
            v-model="guest.email"
            placeholder="Correo electrónico"
            class="w-full border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
            required>
        </div>

        <div class="flex items-center border border-gray-300 custom-rounded-border">
          <input
            ref="password_input"
            type="password"
            id="password"
            name="password"
            v-model="guest.password"
            placeholder="Contraseña"
            class="flex-grow focus:outline-none focus:ring-2 focus:ring-primary input-cancel-border"
            required>
          <i @click="revealPassword" class="flex-none icon-eye px-2 text-xl font-bold"></i>
        </div>
      </div>

      <button :disabled="loading" class="w-full button uppercase bg-primary text-white flex items-center justify-center space-x-2 disabled:bg-gray-200">
        <a-loader class="w-10 h-6" color="text-white" v-if="loading" />
        <span>Iniciar sesión</span>
      </button>
    </form>
    <div class="mt-sm">
      <router-link to="/password-reset" class="text-primary">Olvidé mi contraseña</router-link>
    </div>
  </div>

  <Footer/>

  <transition name="fade" duration="300">
    <div
      class="fixed inset-x-0 max-w-5xl mx-auto bottom-0 bg-white transition transition-all ease-in-out overflow-hidden duration-300"
      :class="{ 'h-full': userSelect !== null, 'h-0': userSelect === null, }"
      v-show="userSelect"
    >
      <header class="text-left font-bold bg-onboarding_gray">
        <button @click="userSelect = false;$emit('register-select', false)" class="flex select-none items-center justify-start space-x-2 py-4 px-4">
          <i class="icon-arrowLeft text-white" />
          <div class="flex flex-col flex-grow items-start">
            <p class="cursor-pointer font-semibold text-white">Regresar</p>
          </div>
        </button>
      </header>
      <div class="h-full flex flex-col items-center space-y-16 bg-onboarding_gray">
        <div class="container mt-24">
          <!--        <img src="../../assets/logo.png" class="mx-auto">-->
          <logo-white class="mx-auto" />
        </div>
        <div class="space-y-8">

          <p class="px-36 text-white text-xl">
            ¿Eres un foodie o un restaurante?
          </p>

          <div class="absolute bottom-0 flex flex-col items-center space-y-4 pb-6 inset-x-0 px-4 overflow-hidden">
            <button class="focus:outline-none py-3 w-full bg-SFRed text-white rounded-full" @click="userType = 1">
              Soy un foodie
            </button>
            <button class="focus:outline-none py-3 w-full bg-white border rounded-full text-SFRed" @click="userType = 2">
              Soy un restaurante
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>

  <a-alert :show="userType !== null" @confirm="redirect" @cancel="userType = null" class="text-onboarding_gray">
    <h2 class="text-xl font-bold">Antes de continuar</h2>
    <p>¿Seguro que deseas continuar como {{ userType === 1 ? 'foodie' : 'restaurante' }}?</p>
  </a-alert>

  <a-alert hide-cancel :show="loginError" title="Ha ocurrido un error" @confirm="loginError = false">
    <p>El Email o la contraseña es incorrecta.</p>
  </a-alert>
  <a-alert hide-cancel :show="loginErrorUser" title="Ha ocurrido un error" @confirm="loginErrorUser = false">
    <p>Usuario no encontrado.</p>
  </a-alert>
  <a-alert hide-cancel :show="loginErrorDisabled" title="Ha ocurrido un error" @confirm="loginErrorDisabled = false">
    <p>Este usuario se encuentra deshabilitado.</p>
  </a-alert>
  <!-- preoader
    <div class="gray_content">
      <div class="lds-dual-ring"></div>
    </div>
    --->
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Logo from '@/components/Logo.vue'
import LogoWhite from '@/components/logo-white-vertical.vue'
import Footer from '@/components/Footer'
// TODO Mensaje de error cuando el usuario no existe
export default {
  name: 'Login',
  components: {
    Logo,
    LogoWhite,
    Footer
  },
  emits: ['register-select'],
  data: () => ({
    guest: {
      email: '',
      password: ''
    },
    loading: false,
    userType: null,
    userSelect: '',
    loginError: false,
    loginErrorUser: false,
    loginErrorDisabled: false
  }),
  methods: {
    ...mapActions({
      login: 'session/login',
      clear: 'clear'
    }),
    handleLogin () {
      this.loading = !this.loading
      this.login(this.guest)
        .then((response) => {
          if (this.$route.query.collaboration_with) {
            this.$router.push({ name: 'Explore', query: this.$route.query })
          } else {
            if (response.isFoodie) {
              this.$router.push({ path: '/collaborations' })
            } else {
              this.$router.push({ path: '/collaboration' })
            }
          }
        })
        .catch((error) => {
          switch (error.response.data.error_code) {
            case 'EA001': {
              this.loginError = !this.loginError
              break
            }
            case 'EA009': {
              this.loginErrorDisabled = !this.loginErrorDisabled
              break
            }
            case 'EA010': {
              this.loginErrorUser = !this.loginErrorUser
              break
            }
          }
        })
        .finally(() => (this.loading = !this.loading))
    },
    redirect () {
      this.$router.push({ name: 'register.native', params: { usertype: this.userType } })
    },
    handleUser () {
      this.userSelect = true
      this.$emit('register-select', true)
    },
    revealPassword () {
      const password = this.$refs.password_input
      if (password.type === 'password') {
        password.type = 'text'
      } else {
        password.type = 'password'
      }
    }
  },
  computed: {
    ...mapGetters(['lang', 'error'])
  },
  mounted () {
    // console.log('from login page', this.$route.path, this.$route.path === '/register')
    if (this.$route.path === '/register') {
      this.userSelect = true
      this.$emit('register-select', true)
    }
  }
}

</script>

<style lang="scss" scoped>
.button{
  margin: auto;
}
input[type="checkbox"]{
  vertical-align: middle;
}
.remember-input{
}
.remember-label{
  margin-left: 5px;
  vertical-align: middle;
}
.login-button{
  //border-left: 1px solid #cacaca;
  //border-right: 1px solid #cacaca;
  //height: auto;
}
.logo-container{
  //margin-top: 20%;
  width: auto;
}
.toast_noty{
  background:white;
  width: 280px;
  margin: 0 auto;
  min-height: 154px;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.19);
  border-radius: 10px;
  max-height: 90vh;
  overflow: scroll;
  position: relative;
}
.header_noti,
.footer_noti{
  padding: 10px 20px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
}
.header_noti{
  text-transform: uppercase;
  text-align: left;
}
.footer_noti{
  text-align: right;
  color: #F2B415;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.body_noti{
  padding: 0 20px 47px 20px;
  .icon_noti{
    font-size: 35px;
    display: inline-block;
    vertical-align: middle;
  }
  .text_noti{
    display: inline-block;
    width: calc(100% - 35px);
    text-align: left;
    line-height: 1.2;
    padding-left: 10px;
    vertical-align: middle;
  }
}
.fixed_cookie{
  background: rgba(0, 0, 0, 0.6) none repeat scroll 0% 0%;
  display: inline-block;
  align-content: center;
  width: 100%;
  bottom: 0px;
  left: 0px;
  z-index: 100;
  text-align: center;
  line-height: 48px;
  color: white;
  position:fixed;
  line-height: 1.7;
  padding: 10px;
  min-height: 48px;
}
</style>
