<template>
  <div class="relative logo-container">
<!--    <router-link>-->
      <!--      <img src="../assets/logo.png" class="logo">-->
      <Logo class="logo" />
<!--    </router-link>-->
  </div>
</template>

<script>
import Logo from '@/components/logos/logo-white-vertical'

export default {
  name: 'Logo-white',
  components: {
    Logo
  }
}
</script>

<style scoped>
.logo{
  display: block;
  margin: 15px auto;
  width: auto;
  max-width: 320px;
}
</style>
